import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// images
import minusIcon from '../../images/minus.svg'; // -
import plusIcon from '../../images/plus.svg'; // +
import productIntroductionBannerImg from '../../images/productIntroductionBanner.png';  // 產品介紹 banner
import dishImg from '../../images/dish_20240820.jpg';  // 餐盤刀叉圖
import reportedGB from '../../images/reportedGB.png'; // 新聞報導背景圖


/*
input:
1. productId: the id of product
*/
class ProductComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onLoadProduct(this.props.productId);
  }
  render() {
    const {
      product, buyQuantity,
      onChangeBuyQuantity, onClikcAddToShoppingCart,
      productId
    } = this.props;
    return (
      <div className="container-fluid padding0">
        <Helmet>
          <title>{product.getIn(["name"])}</title>
          <meta name="description" content={product.getIn(["description"])}></meta>
        </Helmet>
        <div className="row ProductContent align-items-center">
          <div className="col-lg-7 order-xl-2 order-lg-2">
            <p className="Name">{product.getIn(["name"])}</p>
            <div className="DescriptionBox">
              {/*
                product.getIn(["shippingDate"])
                &&
                <div className="ShippingDate">最快到貨日：{product.getIn(["shippingDate"])}</div>
              */}
              <p className="pull-right" dangerouslySetInnerHTML={{ __html: product.getIn(["description"]) }}></p>
              <p className="PackageDescription">包裝：{product.getIn(["packageDescription"])}</p>
              <p>售價：NT $ <span className="ProductsPrice">{product.getIn(["price"])}</span> / {product.getIn(["unit"])}</p>

              <div className="row d-flex align-items-center QuantityBar">
                {
                  this.props.productId !== "103"
                  ?
                    <div className="col-auto mr-auto d-flex align-items-center">
                      <button className="Minus" type="button" onClick={(event) => onChangeBuyQuantity(buyQuantity - 1)}><img src={minusIcon} alt="" /></button>
                      <input className="QuantityNumber" type="number" name="quantity" min="1" max={product.getIn(["quantity"])} value={buyQuantity} onChange={(event) => onChangeBuyQuantity(event.target.value)} disabled />
                      <button className="Plus" type="button" onClick={(event) => onChangeBuyQuantity(buyQuantity + 1)}><img src={plusIcon} alt="" /></button>
                      <p className="col-auto mr-auto">倒數：{product.getIn(["quantity"]) < 0 ? 0 : product.getIn(["quantity"])}</p>
                    </div>
                  :
                    <div className="col-auto mr-auto d-flex align-items-center"></div>
                }
                <div className="col-auto">
                  <p className="hide">售價：NT $ <span className="ProductsPrice">{buyQuantity * product.getIn(["price"])}</span></p>
                </div>
              </div>
            </div>
            <div className="row BtnBox">
              <div className="col-md-6">
                {
                  (product.getIn(["quantity"]) > 0 && this.props.productId !== "103")
                  ?
                    <button className="GBtn" type="button" onClick={(event) => onClikcAddToShoppingCart(this.props.productId)}>加入購物車</button>
                  :
                    <button className="GBtn goSpecials" type="button">
                      <Link to={`/specials`}>前往專屬頁面訂購</Link>
                    </button>
                }
              </div>
              <div className="col-md-6">
                <Link to={`/products`}>
                  <button className="GLBtn" type="button">繼續購物</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 order-xl-1 order-lg-1">
            <img className="img-fluid" src={`/packages/b/${product.getIn(["image"])}`} />
          </div>
        </div>

        { /* 產品介紹區 */
          (product.has("introduction") || product.has("methods"))
          &&
          <div>
            
            <div className="ProductIntroTitle">
              
              <p className="newYearTip">全館滿1500元免運(適用於本島宅配)</p>
              {/*}
              <p className="newYearTip">消費金額滿$3600，即享免運優惠 <span className='newYearTipSmall'>(離島暫無滿額免運優惠，滿 $3600 (含)以上，單一地點運費 200 元。)</span></p>
              <p className="newYearTip">滿6000元以上，該筆訂單再折抵300元（每2000元多折抵100元，消費折抵無上限）</p>
              {*/}
            </div>
            
            {
              product.has("introduction")
              &&
              <div className="ProductIntroContent">
                <div className="row">
                  <div className="col-md-7">
                    <h2>{product.getIn(["introduction", "title"])}</h2>
                    <p className="pull-right" dangerouslySetInnerHTML={{ __html: product.getIn(["introduction", "description"]) }}></p>
                  </div>
                  <div className="col-md-5">
                    <img className="img-fluid" src={`/packages/${product.getIn(["introduction", "image"])}`} />
                  </div>
                </div>
              </div>
            }

            {
              (product.has("introduction") && product.has("method"))
              &&
              <p></p>
            }

            {
              product.has("method")
              &&
              <div className="ProductIntroContent TopLine align-items-center">
                <div className="row">
                  <div className="col-md-5 text-center">
                    <img className="img-fluid" src={dishImg} />
                  </div>
                  <div className="col-md-7">
                    <h2>品嚐方式</h2>
                    <h3 className="pull-right" dangerouslySetInnerHTML={{ __html: product.getIn(["method"]) }}></h3>
                  </div>
                </div>
              </div>
            }
          </div>
        }


        { /* 新聞連結區 */
          product.has("news")
          &&
          <div className="ReportedContent">
            {
              product.getIn(["news"]).map((report, index) => (
                <div><a key={index} href={'/news/' + report.getIn(["newsId"])}><p>{report.getIn(["title"])}</p></a></div>
              ))
            }
          </div>
        }


        { /* 檢測報告區 */
          product.has("inspection")
          &&

          <div className="ProductIntroContent align-items-center">
            <div className="row justify-content-center">
              {
                product.getIn(["inspection"]).map((certificate, index) => (
                  <div className="col-md-4 Certificates" key={index}>
                    <img className="img-fluid" src={certificate} alt="" />
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ProductComponent;
